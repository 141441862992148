.tableHeader {
  font-size: 1.2rem;
  font-weight: bold;
}

.loansMain {
  font-family: "poppins";
}

.pending {
  color: blue;
}

.released {
  color: green;
}
