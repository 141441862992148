.reportsInitialMainDiv {
  display: flex;
  flex-direction: column;
  gap: 50px;
  font-family: "roboto";
}

.released {
  color: green;
}

.pending {
  color: blue;
}

.printBtn {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 10px;
}
