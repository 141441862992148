.modalfont {
  font-family: "poppins";
}

.tableCalculated {
  color: #69c37b;
}

.custom-modal {
  z-index: 1050;
  margin-top: 50px; /* Ensure the modal is above the navbar */
}

.custom-modal .modal-content {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-modal .modal-header {
  background-color: #007bff;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-modal .modal-footer {
  background-color: #f8f9fa;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom-modal .modal-body {
  padding: 20px;
}

.custom-modal .form-group {
  margin-bottom: 15px;
}

.custom-modal .form-label {
  font-weight: bold;
}

.custom-modal .form-control {
  border-radius: 5px;
}

.custom-modal .form-control:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border-color: #17a2b8;
}

.custom-modal .btn {
  border-radius: 5px;
}

.custom-modal .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-modal .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.custom-modal .btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.custom-modal .btn-success:hover {
  background-color: #218838;
  border-color: #218838;
}
