.tableDiv {
  width: 30%;
}

.shareTable {
  width: 100%;
  border-collapse: collapse;
}

.tableHead th {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.tableRow td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.tableRow {
  border-bottom: 1px solid black;
}

.tableRow:last-child {
  border-bottom: none;
}
