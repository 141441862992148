.mainDiv {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.loansTitle {
  color: rgb(105, 195, 123);
  font-weight: 700;
  font-size: 20px;
  font-family: "poppins";
}

.sharesBuyShareDiv {
  display: flex;
  justify-content: start;
  gap: 30px;
}
