.cord {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(105, 195, 123);
  box-shadow: 0px 1px 6px gray;
  height: 150px;
}

.cardText {
  font-weight: 700;
  font-family: "poppins";
  font-size: 24px;
  color: white;
}

.cardTextInner {
  font-size: 22px;
  font-weight: 700;
  font-family: "poppins";
  color: white;
}
