.mainn {
  width: 95%;
  height: 70dvh;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 7px;
  box-shadow: 0px 2px 6px gray; */
  gap: 10px;
}

.leftDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25%;
}

.centerDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.righDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25%;
}

.paymentMethod,
.paymentMethods,
.paymentsBanner,
.paymentsForm,
.centerDiv,
.paymentInformation,
.paymentFor {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionHeader {
  font-family: "poppins";
  font-size: large;
}

.buttonLi {
  cursor: pointer;
}

.buttonLi:hover {
  color: #69c37b;
}
