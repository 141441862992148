.mainGuaranteeDiv {
  display: flex;
  width: 95%;
  flex-direction: column;
  gap: 20px;
}

.action {
  display: flex;
  gap: 10px;
}

.headingTable {
  font-weight: bold;
}

hr {
  color: blue;
}

.green {
  color: #69c37b;
  font-weight: bold;
}
