.mainVirtualAssistantDiv {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.virtualAssistantGuideVideo {
  border: none;
  border-radius: 7px;
  box-shadow: 2px 2px 6px gray;
}
