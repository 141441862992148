.mainLoansDiv {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.loanApplication {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.loansTable {
  width: 95%;
}

.centerModal {
  margin-top: 80px;
}
