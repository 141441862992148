.constitution-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #69c37f;
}

.heading {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.section-heading {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.signatures {
  text-align: center;
  margin-top: 40px;
}
